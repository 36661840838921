<footer class="main-footer">
  <div class="float-right d-none d-sm-block">
    <b>Version</b> {{ appVersion }}
  </div>
  <strong
    >Copyright &copy; 2014-2019
    <a href="http://adminlte.io">AdminLTE.io</a>.</strong
  >
  All rights reserved.
</footer>
